import React, { useState } from 'react'
import { Card, Tag, Tooltip } from 'antd'
import { DateTime } from 'luxon'
import {
    EnvelopeOpen,
    EnvelopeSimple,
    WarningDiamond,
    FilePlus,
    FileText,
    ExclamationMark,
    CheckFat,
    FileMagnifyingGlass,
} from '@phosphor-icons/react'

import { displayMessage } from '../../../helpers/messages'

import api from '../../../services/api'
import { useContext } from 'react'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function NotificationType({ notification, types, handleWithChanges, onClose }) {
    const navigate = useNavigate()
    const {
        title,
        message,
        read,
        id,
        type,
        createdAt,
        followup_id,
        report_id,
        followup_report_risk_id,
    } = notification
    const [readed, setReaded] = useState(read)
    const [colors, setColors] = useState({})
    const { setShowLoading } = useContext(GeneralContext)
    const [risk, setRisk] = useState(null)

    const formatColors = () => {
        const notificationTypes = Object.values(types)
        const typeNotification = notificationTypes.find(item => item.code === type) ?? notificationTypes[0]
        setColors({
            background_color: typeNotification.background_color,
            text_color: typeNotification.text_color,
            icon: makeNewColor(typeNotification.background_color, 50),
        })
    }
    const formattedMessage = message?.replace(/\n/g, '<br/>')
    const makeNewColor = (hex, variacao) => {
        let r = Number.parseInt(Number.parseInt(hex.substring(1, 3), 16) * (variacao / 100 + 1))
        if (r > 255) r = 255
        if (r < 0) r = 0

        let g = Number.parseInt(Number.parseInt(hex.substring(3, 5), 16) * (variacao / 100 + 1))
        if (g > 255) g = 255
        if (g < 0) g = 0

        let b = Number.parseInt(Number.parseInt(hex.substring(5, 7), 16) * (variacao / 100 + 1))
        if (b > 255) b = 255
        if (b < 0) b = 0

        const hexR = r.toString(16).padStart(2, '0')
        const hexG = g.toString(16).padStart(2, '0')
        const hexB = b.toString(16).padStart(2, '0')
        return `#${hexR}${hexG}${hexB}`
    }

    const markAsReaded = async () => {
        if (!readed) {
            try {
                setShowLoading(true)
                await api.notifications.markOneAsRead(id)
                setReaded(true)
                handleWithChanges()
            } finally {
                setShowLoading(false)
            }
        }
    }

    const renderIcon = () => {
        switch (type) {
            case types.NEW_FOLLOW_UP.code:
                return <FileText size={28} />
            case types.GENERATED_REPORT.code:
                return <FilePlus size={28} />
            case types.FOLLOW_UP_ALMOST_LATE.code:
                return <WarningDiamond size={28} />
            case types.PENDING_REPORT.code:
                return <ExclamationMark size={28} />
            case types.CLIENT_CREATED_FOLLOW_UP_TO_APPROVAL.code:
                return <FileMagnifyingGlass size={28} />
            case types.FINISHED_REPORT.code:
                return <CheckFat size={28} />
            case types.FOLLOW_UP_REPORT_RISK_WITHOUT_CLIENT_USER.code:
                return <ExclamationMark size={28} />

            default:
                return readed ? <EnvelopeOpen size={28} /> : <EnvelopeSimple size={28} />
        }
    }

    const renderHeaderButtons = () => {
        return (
            <div className="flex gap-x-2">
                <Tooltip placement="top" title={readed ? displayMessage('READED_NOTIFICATION') : displayMessage('MARK_AS_READ')}>
                    {readed ? (
                        <EnvelopeOpen size={28} style={{ color: colors.background_color }} />
                    ) : (
                        <EnvelopeSimple
                            size={28}
                            onClick={() => markAsReaded()}
                            className="cursor-pointer"
                            style={{ color: colors.background_color }}
                        />
                    )}
                </Tooltip>
            </div>
        )
    }

    const handleClickOnCard = () => {
        // if (notification.type == types.CLIENT_CREATED_FOLLOW_UP_TO_APPROVAL.code && notification?.report_id && notification?.followup_id) {
        if (type == types.FINISHED_REPORT.code && report_id) {
            markAsReaded()
            navigate(`/reports?report_id=${report_id}`)
            onClose()
        } else if (type == types.FOLLOW_UP_FINISHED.code && report_id && followup_id) {
            markAsReaded()
            navigate(`/reports/${report_id}?followups=${true}`)
            onClose()
        } else if (
            (   
                type == types.NEW_FOLLOW_UP.code ||
                type == types.HIL_RETURNED_FOLLOW_UP.code ||
                type == types.CLIENT_NOT_RETURNED_FOLLOW_UP.code
            ) && report_id && followup_id
        ) {
            markAsReaded()
            if (followup_report_risk_id) {
                navigate(
                    `/followups?followup_id=${followup_id}&followup_report_risk_id=${followup_report_risk_id}`
                )
            } else {
                navigate(`/reports/${report_id}/follow-up/${followup_id}`)
            }
            onClose()
        } else if (
            (
                type == types.CLIENT_CREATED_FOLLOW_UP_TO_APPROVAL.code ||
                type == types.FOLLOW_UP_REPORT_RISK_STATUS_CHANGED.code
            ) && followup_report_risk_id) {
            markAsReaded()
            navigate(`/reports/${report_id}/follow-up/${followup_id}?&followup_report_risk_id=${followup_report_risk_id}`)           
            onClose()
        } else if (notification?.report_id && notification?.followup_id) {
            markAsReaded()
            navigate(`/reports/${notification.report_id}/follow-up/${notification.followup_id}`)
            onClose()
        }
    }

    useEffect(() => {
        formatColors()
    }, [])

    useEffect(() => {
        if (followup_report_risk_id) {
            loadRisk()
        }
    }, [followup_report_risk_id])

    const loadRisk = async () => {
        try {
            const { data } = await api.followupReportRisk.find(followup_report_risk_id)
            setRisk(data.ReportRisk.Risk)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Card
                className="rounded"
                bodyStyle={{
                    backgroundColor: colors.background_color,
                    color: colors.text_color,
                    padding: '0px',
                }}
            >
                <div
                    className="flex justify-between items-center mt-0 mx-0 px-2 font-bold py-2"
                    style={{
                        border: `1px solid ${colors.background_color}`,
                        color: colors.background_color,
                        backgroundColor: colors.text_color,
                    }}
                >
                    {risk ? (
                        <Tooltip
                            title={risk?.description}
                            overlayStyle={{ maxWidth: 500 }}
                            overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                            placement="leftTop"
                            autoAdjustOverflow
                        >
                            {title}
                        </Tooltip>
                    ) : (
                        title
                    )}
                    <div className="">{renderHeaderButtons()}</div>
                </div>
                <div className="cursor-pointer" onClick={() => handleClickOnCard()}>
                    <div className="flex justify-end mt-1 mr-1">
                        <Tag color={colors.icon} className="m-0">
                            {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
                        </Tag>
                    </div>
                    <div className="flex items-center mx-5 mb-5 mt-1">
                        <div
                            className="flex mr-5 rounded-full items-center justify-center w-[50px] h-[50px]"
                            style={{
                                border: `1px solid ${colors.text_color}`,
                                backgroundColor: colors.icon,
                            }}
                        >
                            {renderIcon()}
                        </div>

                        <div dangerouslySetInnerHTML={{ __html: formattedMessage }} />
                    </div>
                </div>
            </Card>
        </>
    )
}
