import { AutoComplete, Checkbox, Form, Input, Modal } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import { displayMessage } from '../../../helpers/messages'
import api from '../../../services/api'
import { SL_ClipLoader } from '../../../components/loading/ClipLoader'
import { SL_Select } from '../../../components/selects/Select'
import { GeneralContext } from '../../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../../enums/EAlertTypes'
import { MultiSelect } from '../../../components/selects/MultiSelect'
import EActivationStatus from '../../../enums/EActivationStatus'
import { SL_Input } from '../../../components/input/Input'

const STATUS_SUCCESS = 200
const maxRequesterForFilter = 5

export function ModalCreateActivity({ isOpen, onClose, reloadRisks }) {
    const { showAlert, windowHeight } = useContext(GeneralContext)

    const formRef = useRef()

    const [loading, setLoading] = useState(false)

    const [normatives, setNormatives] = useState([])
    const [selectedNormatives, setSelectedNormatives] = useState([])

    const [riskTypes, setRiskTypes] = useState([])
    const [selectedRiskType, setSelectedRiskType] = useState(null)

    const [riskActivities, setRiskActivities] = useState([])
    const [selectedRiskActivity, setSelectedRiskActivity] = useState(null)

    const [riskKinds, setRiskKinds] = useState([])
    const [selectedRiskKind, setSelectedRiskKind] = useState(null)

    const [isRecommendationSelected, setIsRecommendationSelected] = useState(false)

    useEffect(() => {
        loadAllData()
    }, [])

    useEffect(() => {
        if (selectedRiskType) {
            loadRiskActivities(selectedRiskType)
        } else {
            setRiskActivities([])
            setSelectedRiskActivity(null)
        }
    }, [selectedRiskType])

    useEffect(() => {
        setRiskKinds([])
        setSelectedRiskKind(null)
    }, [selectedRiskActivity])

    const loadAllData = async () => {
        setLoading(true)

        await Promise.all([loadRiskTypes()])

        setLoading(false)
    }

    

    const loadRiskTypes = async () => {
        try {
            const { data } = await api.riskTypes.list()

            setRiskTypes(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadRiskActivities = async risk_type_id => {
        try {
            const { data } = await api.riskActivities.list({ risk_type_id })

            setRiskActivities(data)
        } catch (error) {
            console.log(error)
        }
    }


    const onSubmit = async risk => {
        setLoading(true)
        try {
            const auxActivity = {
                risk_activity: risk.risk_activity || selectedRiskActivity,
                risk_kind : risk.risk_kind ||selectedRiskKind,
                risk_type: selectedRiskType
                
            }
            let response = null

                response = await api.riskActivities.create(auxActivity)

            if (response.status === STATUS_SUCCESS) {
                onClose(false)
                setIsNewActivity(false)
                setIsNewKind(false)
                showAlert('ACTIVITY_CREATED_SUCCESSFULLY', EAlertTypes.SUCCESS)
                reloadRisks
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
  
    const handleRiskTypeChange = risk_type_id => {
        setSelectedRiskType(risk_type_id)
        setSelectedRiskActivity(null)
    }

    const handleRiskActivityChange = risk_activity_id => {
        setSelectedRiskActivity(risk_activity_id)
    }

    const [isNewActivity, setIsNewActivity] = useState(false)
    const [isNewKind, setIsNewKind] = useState(false)
    return (
        <Modal
            title={displayMessage('ACTIVITY_CREATION')}
            centered
            open={isOpen}
            onOk={() => formRef.current.submit()}
            confirmLoading={loading}
            onCancel={() => onClose(false)}
            okText={displayMessage('FINISH')}
            maskClosable={false}
            bodyStyle={{
                maxHeight: 0.8 * windowHeight,
            }}
        >
            {loading ? (
                <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                    <SL_ClipLoader loading={true} />
                </div>
            ) : (
                <Form onFinish={onSubmit} className="max-w-[700px] w-full" layout="vertical" ref={formRef}>

                    <div className="flex flex-col gap-1">
                        
                        {isRecommendationSelected && (
                            <Form.Item
                                name="recommendation_description"
                                rules={[{ required: true, message: displayMessage('RECOMMENDATION') }]}
                                className="mb-4"
                            >
                                <Input.TextArea
                                    disabled
                                    autoSize={{ minRows: 1 }}
                                    placeholder={displayMessage('RECOMMENDATION')}
                                    className={'w-full'}
                                />
                            </Form.Item>
                        )}
                    </div>

                    <div className="flex flex-col gap-1 mb-4">
                        <div>{displayMessage('RISK_TYPE')}</div>
                        <SL_Select
                            maxTagCount="responsive"
                            className="mb-1 h-[28px] rounded-sm"
                            listItemHeight={100}
                            title={displayMessage('RISK_TYPES')}
                            placeholder={displayMessage('RISK_TYPES')}
                            onChange={handleRiskTypeChange}
                            value={riskTypes.find(e => selectedRiskType == e.id)?.name}
                            options={riskTypes.map(e => ({ label: e.name, value: e.id }))}
                            required={true}
                            allowClear
                        />
                    </div>
                    

                    <div className="flex flex-col gap-1 ">
                    <Form.Item
                        name="risk_activity"
                        rules={[{ required: isNewActivity, message: displayMessage('RISK_ACTIVITIES_REQUIRED') }]}
                    >
                        <div>{displayMessage('RISK_ACTIVITY')}</div>
                        {!isNewActivity ? (
                            <SL_Select
                                maxTagCount="responsive"
                                className="mb-1 h-[28px] rounded-sm"
                                listItemHeight={100}
                                disabled={selectedRiskType == null}
                                title={displayMessage('RISK_ACTIVITIES')}
                                placeholder={displayMessage('RISK_ACTIVITIES')}
                                onChange={handleRiskActivityChange}
                                value={riskActivities.find(e => selectedRiskActivity == e.id)?.name}
                                options={riskActivities.length > 0 ? riskActivities?.map(e => ({ label: e?.name, value: e?.id })) : []}
                                allowClear
                            /> 
                        ) : ( 
                            <SL_Input
                                className="mb-1 h-[28px] rounded-sm"
                                disabled={selectedRiskType == null}
                                title={displayMessage('RISK_ACTIVITIES')}
                                placeholder={displayMessage('RISK_ACTIVITIES')}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    formRef.current.setFieldsValue({ risk_activity: value });
                                    setSelectedRiskActivity(value);
                                }}
                                allowClear
                            />
                        )}

                        <Checkbox
                        onChange={() => {
                            setIsNewActivity(!isNewActivity)
                            setIsNewKind(false)
                        }}
                        >
                        {displayMessage('NEW_ACTIVITY')}
                        </Checkbox>
                            </Form.Item>
                    </div>

                    <div className="flex flex-col gap-1">
                        <div>{displayMessage('RISK_KIND')}</div>
                        <Form.Item
                            name="risk_kind"
                            // rules={[{ required: isNewKind == true || isNewActivity == true, message: displayMessage('RISK_KINDS_REQUIRED') }]}
                            rules={[{ required: true, message: displayMessage('RISK_KINDS_REQUIRED') }]}
                        >
                                <SL_Input
                                    className="mb-1 h-[28px] rounded-sm"
                                    ttitle={displayMessage('RISK_KINDS')}
                                    placeholder={displayMessage('RISK_KINDS')}
                                    disabled={
                                        (isNewActivity && !selectedRiskActivity) || // Caso input e vazio
                                        (!isNewActivity && !selectedRiskActivity) // Caso select e não selecionado
                                    }
                                    onChange={(e) => {
                                        formRef.current.setFieldsValue({ risk_kind: e.target.value });
                                    }}
                                    allowClear
                                />
                       
                        </Form.Item>
                    </div>
                </Form>
            )}
        </Modal>
    )
}
