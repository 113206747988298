import { useContext, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { SL_ClipLoader } from '../../../../../components/loading/ClipLoader'
import api from '../../../../../services/api'
import { Card, Checkbox, Collapse, Divider, Image, List, Popconfirm, Tabs, Tooltip, Typography } from 'antd'
import { displayMessage } from '../../../../../helpers/messages'
// import EFollowUpStatus from '../../../../../enums/EFollowUpStatus'
import { SL_Select } from '../../../../../components/selects/Select'
import { SL_Button } from '../../../../../components/buttons/Button'
import { ModalConfirmationFinalizateFollowup } from './ModalConfirmationFinalizateFollowup'
import { GeneralContext } from '../../../../../context/GeneralContext/GeneralContext'
import { EAlertTypes } from '../../../../../enums/EAlertTypes'
import { CreateRisk } from '../../CreateRisk/CreateRisk'
import ModalChat from './ModalChat'
import { SL_Grid } from '../../../../../components/grid/SL_Grid'
import followup_approvals_grid_columns from '../grid_columns/followup_approvals_grid_columns'
const { Panel } = Collapse
import './FollowUpDetails.css'
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import CustomToolbar from '../../../../../components/grid/CustomToolbar'
import { ModalCreateMessage } from './ModalCreateMessage'

const { Text } = Typography
const FOLLOW_UP_RISKS_KEY = 1
const max_attachment_characters = 30

export function FollowUpDetails({}) {
    let eRiskTypes = null

    const { followUpId, reportId } = useParams()

    const { showAlert, mobile_mode } = useContext(GeneralContext)

    const [followUp, setFollowUp] = useState(null)

    const [loading, setLoading] = useState(false)
    const [previousFollowUp, setPreviousFollowUp] = useState(null)

    const [loadingStatus, setLoadingStatus] = useState(true)

    const [modalConfirmationFinalizateFollowupIsOpen, setModalConfirmationFinalizateFollowupIsOpen] = useState(false)
    const [isModalConversationOpen, setIsModalConversationOpen] = useState(false)
    const [currentFollowupReportRisk, setCurrentFollowupReportRisk] = useState([])
    const [followupApprovals, setFollowupApprovals] = useState([])
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedAttachmentId, setSelectedAttachmentId] = useState(null)
    const [isModalCreateMessageIsOpen, setIsModalCreateMessageIsOpen] = useState(false)
    const [currentAttachmentSelected, setCurrentAttachmentSelected] = useState(null)
    const [followupReportRisks, setFollowupReportRisks] = useState([])
    const [clientUserAssociatedName, setClientUserAssociatedName] = useState('')

    const [regularClientUsers, setRegularClientUsers] = useState([])
    const [clientId, setClientId] = useState(null)
    

    const [eFollowUpStatus, setEFollowUpStatus] = useState({})

    let [searchParams, setSearchParams] = useSearchParams()
    let followup_report_risk_id = searchParams.get('followup_report_risk_id')
    const [collapseActiveKey, setCollapseActiveKey] = useState(followup_report_risk_id || 0)
    const [tempCollapseActiveKey, setTempCollapseActiveKey] = useState(followup_report_risk_id || 0)


    useEffect(() => {
        loadFollowUp()
        loadRegularClientUsers()
    }, [])

    useEffect(() => {
        if (followUp) {
            loadPreviousFollowup()
        }
    }, [followUp])

    useEffect(() => {
        loadFollowupApprovals()
    }, [currentFollowupReportRisk])

    useEffect(() => {
        if (followUpId) {
            loadFollowUpReportRisks()
        }
    }, [followUpId])

    useEffect(()=>{
        if (tempCollapseActiveKey && followupReportRisks?.length > 0 && regularClientUsers?.length > 0) {
            setCollapseActiveKey(parseInt(tempCollapseActiveKey))
            handleOnCollapseChange([tempCollapseActiveKey])
            setTempCollapseActiveKey(null)
        }
    },[followupReportRisks, regularClientUsers])

    useEffect(()=>{console.log("collapseActiveKey: ",collapseActiveKey)}, [collapseActiveKey])

    const loadRegularClientUsers = async () => {
        try {
            const { data } = await api.followupReportRisk.list({ followup_id: followUpId, is_from_adm: true })
            if (data[0].Address) {
                setRegularClientUsers(data)
                setClientId(data[0].clientUserAddresses.Address.client_id)
            } else {
                setRegularClientUsers(data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const updateFollowupReportRisk = async (followup_approval, attachmentSelected = null, deselected = false) => {
        try {
            let body = { followup_approval_id: followup_approval?.id }
            if (!deselected) {
                if (attachmentSelected) {
                    body = {
                        ...body,
                        attachment_description: followup_approval?.description,
                        attachment_id: attachmentSelected,
                        is_attachment_selected: true,
                    }
                } else {
                    body = {
                        ...body,
                        attachment_id: null,
                        is_attachment_selected: false,
                    }
                }
            } else {
                body = {
                    ...body,
                    attachment_description: null,
                    attachment_id: null,
                    is_attachment_selected: false,
                }
            }
            await api.followupReportRisk.updateAttachmentRelation(currentFollowupReportRisk?.id, body)
            await loadFollowUp()
            await loadFollowUpReportRisks()
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnDownload = async attachment => {
        try {
            const { data } = await api.attachments.openAttachment(attachment.id, { responseType: 'blob' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(data)
            link.download = `${attachment?.name}`
            link.click()
            const parent = link.parentNode
            if (link && parent) {
                parent.removeChild(link)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadPreviousFollowup = async () => {
        try {
            const { data } = await api.followUps.list({ report_id: reportId })
            const current_followup_sequence = followUp?.sequence
            const previous_follow_up = data.find(followup => followup.sequence == current_followup_sequence - 1)
            if (previous_follow_up) {
                setPreviousFollowUp(previous_follow_up)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const loadFollowupApprovals = async () => {
        try {
            const { data } = await api.followupApprovals.list({ followup_report_risk_id: currentFollowupReportRisk?.id, order_by_date_desc: true })
            setFollowupApprovals(data)
            setSelectedRow(data.find(fu => fu.is_attachment_selected))
        } catch (error) {
            console.log(error)
        }
    }

    const loadFollowUp = async () => {
        if (!Object.keys(eFollowUpStatus).length) {
            const enumRes = await api.enums.find('EFollowUpStatus')
            setEFollowUpStatus(enumRes.data)
        }
        try {
            const { data } = await api.followUps.find(followUpId)

            setFollowUp(data)
        } catch (error) {
            console.log(error)
        }
    }

    const loadFollowUpReportRisks = async () => {
        try {
            if (!eRiskTypes) {
                const enumRes = await api.enums.find('ERiskTypes')
                eRiskTypes = enumRes.data
            }
            const { data } = await api.followupReportRisk.list({ followup_id: followUpId })
            let result = await createFollowUpReportRiskSequence(data)
            setFollowupReportRisks(result)
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnStatusChange = async (value, id, index) => {
        setCollapseActiveKey(index)
        setLoadingStatus(false)
        try {
            await api.followupReportRisk.changeStatus({ status: value, id })
            await loadFollowUp()
            await loadFollowUpReportRisks()
            setLoadingStatus(true)
        } catch (error) {
            setLoadingStatus(true)
            console.error(error)
        } finally {
            setLoadingStatus(true)
        }
    }
    const handleClientUserAssociatedChange = async (value, followup_report_risk, index) => {
        setCollapseActiveKey(index)
        try {
            const id = followup_report_risk.id
            await api.followupReportRisk.changeClientUserResponsible({ client_user_associated_id: value, id })
            await loadFollowUp()
            await loadFollowUpReportRisks()
            // setCurrentClientUserAssociatedId(value)
        } catch (error) {
            console.error(error)
        } finally {
            handleOnClientUserAssociatedChange(value, followup_report_risk, index)
        }
    }

    const handleOnFinalization = async () => {
        await loadFollowUp()
        for (let i = 0; i < followupReportRisks.length; i++) {
            const followup_report_risk = followupReportRisks[i]

            if (followup_report_risk.status == null) {
                showAlert(displayMessage('ERROR_FOLLOWUP_FINALIZATED_WITH_SOME_FOLLOWUP_RISK_WITH_NULL_STATUS'), EAlertTypes.ERROR)
                return
            }

            if (followup_report_risk.client_user_associated_id == null) {
                showAlert(displayMessage('ERROR_FOLLOWUP_FINALIZATED_WITH_SOME_FOLLOWUP_RISK_WITH_NULL_CLIENT_USER_ASSOCIATED'), EAlertTypes.ERROR)
                return
            }
        }

        setModalConfirmationFinalizateFollowupIsOpen(true)
    }

    const renderList = (dataSource, emptyText) => {
        return (
            <>
                <List
                    size="small"
                    locale={{ emptyText }}
                    className="rounded p-0 m-0"
                    itemLayout="horizontal"
                    dataSource={dataSource}
                    renderItem={item => (
                        <List.Item className="p-2">
                            <p>{item.description}</p>
                        </List.Item>
                    )}
                />
            </>
        )
    }

    const handleOnCollapseChange = key => {
        if (key.length == 0) {
            setCurrentFollowupReportRisk(null)
            return
        }
        const followup_report_risk = followupReportRisks.find(report_risk => report_risk?.id == key[0])
        setCurrentFollowupReportRisk(followup_report_risk)
        setSelectedAttachmentId(followup_report_risk?.Attachment?.id)
        setCurrentAttachmentSelected(followup_report_risk?.Attachment)
        const user = regularClientUsers.find(e => e.id === followup_report_risk?.client_user_associated_id)
        setClientUserAssociatedName(user ? `${user.first_name} ${user.last_name}` : null)
    }

    const handleOnClientUserAssociatedChange = async (value, followup_report_risk, index) => {
        const user = regularClientUsers.find(e => e.id === value)
        setClientUserAssociatedName(user ? `${user.first_name} ${user.last_name}` : null)
    }

    const returnPreviousReportRiskStatus = followup_report_risk => {
        let status_name = displayMessage('NO_PREVIOUS_FOLLOWUP')
        if (previousFollowUp) {
            const prev_frr = previousFollowUp.FollowupReportRisks.find(
                e => e?.ReportRisk.sequence == parseInt(followup_report_risk.sequence_number)
            )
            if (prev_frr) {
                if (prev_frr.status != null) {
                    status_name = Object.values(eFollowUpStatus)?.find(e => e.code == prev_frr?.status)?.description
                }
                else{
                    status_name = displayMessage('NO_STATUS_ALREADY_SELECTED')
                }
            }
        }
        return <p>{status_name}</p>
    }

    const extraButtons = {
        field: 'buttons',
        headerName: displayMessage('SELECT_MESSAGE'),
        width: 160,
        align: 'center',
        renderCell: function (params) {
            const { row } = params
            return (
                <div className="flex gap-x-3" style={{ padding: 2 }}>
                    {selectedRow?.id == row?.id ? (
                        <Checkbox
                            color="primary"
                            checked={selectedRow?.id == row?.id}
                            onChange={async () => {
                                if (selectedRow && selectedRow?.id != row?.id) {
                                    showAlert(displayMessage('ERROR_DIFFERENT_MESSAGE_ALREADY_SELECTED'), EAlertTypes.ERROR)
                                } else {
                                    setSelectedRow(null)
                                    setSelectedAttachmentId(null)
                                    setCurrentAttachmentSelected(null)
                                    await updateFollowupReportRisk(row, null, true)
                                    await loadFollowupApprovals()
                                }
                            }}
                            disabled={row?.Attachments?.length == 0 || followUp?.end_date}
                        />
                    ) : (
                        <Popconfirm
                            placement="topRight"
                            title={displayMessage('SELECT_MESSAGE')}
                            description={<div className="w-[300px]">{displayMessage('INFO_SELECT_MESSAGE')}</div>}
                            onConfirm={async () => {
                                if (selectedRow && selectedRow?.id != row?.id) {
                                    showAlert(displayMessage('ERROR_DIFFERENT_MESSAGE_ALREADY_SELECTED'), EAlertTypes.ERROR)
                                } else {
                                    setSelectedRow(row)
                                    await updateFollowupReportRisk(row)
                                    showAlert(displayMessage('SELECT_ATTACHMENT_FOR_MESSAGE'), EAlertTypes.WARNING)
                                }
                            }}
                            okText={displayMessage('YES')}
                            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                        >
                            <Checkbox
                                color="primary"
                                checked={selectedRow?.id == row?.id}
                                disabled={row?.Attachments?.length == 0 || followUp?.end_date}
                            />
                        </Popconfirm>
                    )}
                </div>
            )
        },
    }

    const selectAttachment = {
        field: 'select_attachment',
        headerName: displayMessage('SELECT_ATTACHMENT_TO_REPORT'),
        width: 250,
        align: 'center',
        renderCell: function (params) {
            const { row } = params
            const max_attachment_name_length = 20
            const items = row.Attachments.map(attachment => {
                return {
                    value: attachment?.id,
                    label:
                        attachment.name.length >= max_attachment_name_length
                            ? `${attachment.name.slice(0, max_attachment_name_length)}...`
                            : attachment.name,
                    full_name: attachment.name,
                }
            })
            return (
                <div className="flex gap-x-3" style={{ padding: 2 }}>
                    <Tooltip
                        title={items.find(attachment => attachment.value == selectedAttachmentId)?.full_name}
                        overlayStyle={{ maxWidth: 500 }}
                        overlayInnerStyle={{ backgroundColor: 'white', color: 'black', textAlign: 'justify' }}
                    >
                        <SL_Select
                            options={items}
                            value={selectedRow?.id == row.id ? selectedRow?.FollowupReportRisk?.Attachment?.id : null}
                            disabled={selectedRow?.id != row?.id || followUp?.end_date}
                            onChange={async e => await handleOnSelectAttachment(e, row)}
                            placeholder={displayMessage('SELECT_ATTACHMENT')}
                        />
                    </Tooltip>
                </div>
            )
        },
    }

    async function findAttachment(attachment_id) {
        try {
            const { data } = await api.attachments.find(attachment_id)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    const handleOnSelectAttachment = async (e, followup_approval) => {
        let attachment_id = e
        if (attachment_id == undefined || attachment_id == '' || attachment_id == null) {
            attachment_id = null
        }
        const attachment_finded = await findAttachment(attachment_id)
        setSelectedAttachmentId(attachment_id)
        setCurrentAttachmentSelected(attachment_finded)
        await updateFollowupReportRisk(followup_approval, e)
        await loadFollowupApprovals()
        await loadFollowUpReportRisks()
    }

    const getSequenceDescription = async data => {
        const risk_type = data?.Risk?.RiskKind?.RiskActivity?.RiskType
        const risk_type_acronym = Object.values(eRiskTypes).find(e => risk_type?.code == e.code)?.acronym
        return risk_type_acronym
    }

    const createFollowUpReportRiskSequence = async riskKindId => {
        let sequence,
            max_one_digit = 9,
            max_two_digits = 99
        const FollowUpFormater = []

        if (!Array.isArray(riskKindId)) {
            console.error('riskKindId is not an array or is undefined')
            return FollowUpFormater
        }
        const ReportRisk = riskKindId.map(e => ({
            ...e.ReportRisk,
            originalStatus: e.status, // Adiciona o status original (se remover, pega o status do risco e da ruim)
            originalId: e.id, //Adicinona id original (as msg precisam dele)
            Attachment: e.Attachment,
            original_attachment_id: e.attachment_id,
            client_user_associated_id: e.client_user_associated_id,
        }))
        const sortedRisks = ReportRisk.sort((a, b) => a.id - b.id)

        for (let i = 0; i < sortedRisks.length; i++) {
            const report_risk = sortedRisks[i]
            if (report_risk.is_from_app) {
                continue
            }
            const risk_type_acronym = await getSequenceDescription(report_risk)
            if (i < max_one_digit) {
                sequence = `00${i + 1}`
            } else if (i < max_two_digits) {
                sequence = `0${i + 1}`
            } else {
                sequence = `${i + 1}`
            }
            FollowUpFormater.push({
                ...report_risk,
                sequence: `${risk_type_acronym}${sequence}`,
                sequence_number: sequence,
                FollowupApprovals: sortedRisks[i].FollowupApprovals ?? [],
                status: sortedRisks[i].originalStatus, // Mantém o status original
                id: report_risk.originalId, //mantém o id original
                Attachment: report_risk.Attachment,
                attachment_id: sortedRisks[i].original_attachment_id,
                client_user_associated_id: sortedRisks[i].client_user_associated_id,
            })
        }
        return FollowUpFormater
    }

    // const updatedFollowupReportRisks = followupReportRisks.map(risk => {
    //     const user = regularClientUsers.find(user => user.id === risk.client_user_associated_id)
    //     return {
    //         ...risk,
    //         client_user_associated_name: user ? `${user.first_name} ${user.last_name}` : 'Usuário não encontrado',
    //     }
    // })
    return (
        <>
            {loading ? (
                <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center z-[9998] bg-sl-gray-500 bg-opacity-10">
                    <SL_ClipLoader loading={true} />
                </div>
            ) : (
                <>
                    <Tabs
                        defaultActiveKey={FOLLOW_UP_RISKS_KEY}
                        items={[
                            {
                                key: FOLLOW_UP_RISKS_KEY,
                                label: displayMessage('FOLLOWUP'),
                                children: (
                                    <>
                                        {modalConfirmationFinalizateFollowupIsOpen && (
                                            <ModalConfirmationFinalizateFollowup
                                                isOpen={modalConfirmationFinalizateFollowupIsOpen}
                                                onClose={setModalConfirmationFinalizateFollowupIsOpen}
                                                followUpId={followUp?.id}
                                            />
                                        )}
                                        <div className="flex w-full">
                                            <Collapse
                                                defaultActiveKey={collapseActiveKey}
                                                ghost
                                                accordion
                                                size="large"
                                                className="w-full"
                                                onChange={handleOnCollapseChange}
                                            >
                                                {!followUp?.end_date && (
                                                    <>
                                                        <span className="text-lg">{displayMessage('IDENTIFY_NEW_RISK')}</span>
                                                        <Divider className="mt-3 mb-3" />
                                                        <div className="mb-3">
                                                            <CreateRisk
                                                                followUpId={followUpId}
                                                                reportId={reportId}
                                                                clientId={clientId}
                                                                loadFollowUp={loadFollowUp}
                                                                followUp={followUp}
                                                                regularClientUsers={regularClientUsers}
                                                                setCollapseActiveKey={setCollapseActiveKey}
                                                                onRiskCreated={loadFollowUpReportRisks}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                <span className="text-lg">{displayMessage('RISKS')}</span>
                                                <Divider className="mt-3" />

                                                {followupReportRisks.map((followup_report_risk, index) => {
                                                    const e = followup_report_risk
                                                    const { Attachment } = followup_report_risk
                                                    return (
                                                        <Panel
                                                            key={followup_report_risk?.id}
                                                            header={`${followup_report_risk.sequence} - ${e?.Risk?.title}`}
                                                            className="w-full"
                                                        >
                                                            <div className={`flex w-full ${mobile_mode ? 'flex-col' : 'flex-row '}`}>
                                                                {mobile_mode ? (
                                                                    <>
                                                                        <div className="flex-flex col">
                                                                            <div className="flex flex-row">
                                                                                <div className="p-1">{displayMessage('OLD_STATUS')}:</div>
                                                                                <div className="ml-4">
                                                                                    {returnPreviousReportRiskStatus(followup_report_risk)}
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex flex-row mt-5">
                                                                                <div className="flex flex-row">
                                                                                    <div className="p-1">{displayMessage('CHANGE_STATUS')}:</div>
                                                                                    {loadingStatus == true && (
                                                                                        <SL_Select
                                                                                            disabled={followUp?.end_date}
                                                                                            maxTagCount="responsive"
                                                                                            className="mb-1 h-[28px] w-[250px] rounded-sm"
                                                                                            listItemHeight={100}
                                                                                            title={displayMessage('STATUS')}
                                                                                            placeholder={displayMessage('STATUS')}
                                                                                            onChange={value =>
                                                                                                handleOnStatusChange(
                                                                                                    value,
                                                                                                    followup_report_risk.id,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            value={followup_report_risk.status}
                                                                                            options={Object.values(eFollowUpStatus)?.map(e => ({
                                                                                                label: e.description,
                                                                                                value: e.code,
                                                                                            }))}
                                                                                            allowClear
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex w-1.5/4 ml-1 min-w-80 mr-20 items-center justify-center">
                                                                                <div>{displayMessage('CLIENT_USER_ASSOCIATED')}:</div>
                                                                                {loadingStatus == true && (
                                                                                    <SL_Select
                                                                                        disabled={followUp?.end_date}
                                                                                        maxTagCount="responsive"
                                                                                        className="mb-1 ml-2 h-[28px] w-[250px] rounded-sm"
                                                                                        listItemHeight={100}
                                                                                        title={displayMessage('CLIENT_USER_ASSOCIATED')}
                                                                                        placeholder={displayMessage('CLIENT_USER_ASSOCIATED')}
                                                                                        onChange={value =>
                                                                                            handleClientUserAssociatedChange(
                                                                                                value,
                                                                                                followup_report_risk,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        value={clientUserAssociatedName}
                                                                                        options={regularClientUsers.map(e => ({
                                                                                            label: `${e.first_name} ${e.last_name}`,
                                                                                            value: e.id,
                                                                                        }))}
                                                                                        allowClear
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                            {Attachment?.file_type.includes('image') ? (
                                                                                <div className={`flex w-full items-center mt-5`}>
                                                                                    <div className="mr-5">{displayMessage('IMAGE')}:</div>
                                                                                    <div className="flex justify-center">
                                                                                        <Tooltip title={displayMessage('CLICK_IMAGE_TO_OPEN')}>
                                                                                            <Image
                                                                                                src={api.attachments.imageDownload(
                                                                                                    followup_report_risk?.attachment_id
                                                                                                )}
                                                                                                width={45}
                                                                                            />
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className={`flex w-full items-center mt-5`}>
                                                                                    <div className="mr-5">{displayMessage('DOCUMENT')}:</div>
                                                                                    <a
                                                                                        className="text-center"
                                                                                        key={Attachment?.id}
                                                                                        href={Attachment?.url}
                                                                                        download
                                                                                        style={{ display: 'block', marginBottom: '4px' }}
                                                                                        onClick={() => handleOnDownload(Attachment)}
                                                                                    >
                                                                                        <Tooltip
                                                                                            title={Attachment?.name}
                                                                                            overlayStyle={{ maxWidth: 500 }}
                                                                                            overlayInnerStyle={{
                                                                                                backgroundColor: 'white',
                                                                                                color: 'black',
                                                                                                textAlign: 'justify',
                                                                                            }}
                                                                                        >
                                                                                            {Attachment?.name.length > max_attachment_characters
                                                                                                ? `${Attachment?.name.slice(
                                                                                                      0,
                                                                                                      max_attachment_characters
                                                                                                  )}...`
                                                                                                : Attachment?.name}
                                                                                        </Tooltip>
                                                                                    </a>
                                                                                </div>
                                                                            )}
                                                                            <div className="flex flex-row mt-3">
                                                                                <SL_Button
                                                                                    onClick={() => {
                                                                                        setIsModalConversationOpen(true)
                                                                                    }}
                                                                                >
                                                                                    {displayMessage('CLIENT_FOLLOWUP')}
                                                                                </SL_Button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className="flex flex-row w-full justify-between">
                                                                        <div className="flex w-1.5/4 mr-5 min-w-56 items-center justify-center">
                                                                            <div>{displayMessage('OLD_STATUS')}:</div>
                                                                            <div className="ml-2">
                                                                                {returnPreviousReportRiskStatus(followup_report_risk)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex w-1.5/4 ml-1 min-w-80 mr-20 items-center justify-center">
                                                                            <div>{displayMessage('CHANGE_STATUS')}:</div>
                                                                            {loadingStatus == true && (
                                                                                <SL_Select
                                                                                    disabled={followUp?.end_date}
                                                                                    maxTagCount="responsive"
                                                                                    className="mb-1 ml-2 h-[28px] w-[250px] rounded-sm"
                                                                                    listItemHeight={100}
                                                                                    title={displayMessage('STATUS')}
                                                                                    placeholder={displayMessage('STATUS')}
                                                                                    onChange={value =>
                                                                                        handleOnStatusChange(value, followup_report_risk.id, index)
                                                                                    }
                                                                                    value={followup_report_risk.status}
                                                                                    options={Object.values(eFollowUpStatus)?.map(e => ({
                                                                                        label: e.description,
                                                                                        value: e.code,
                                                                                    }))}
                                                                                    allowClear
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className="flex w-1.5/4 ml-1 min-w-80 mr-20 items-center justify-center">
                                                                            <div>{displayMessage('CLIENT_USER_ASSOCIATED')}:</div>
                                                                            {loadingStatus == true && (
                                                                                <SL_Select
                                                                                    disabled={followUp?.end_date}
                                                                                    maxTagCount="responsive"
                                                                                    className="mb-1 ml-2 h-[28px] w-[250px] rounded-sm"
                                                                                    listItemHeight={100}
                                                                                    title={displayMessage('CLIENT_USER_ASSOCIATED')}
                                                                                    placeholder={displayMessage('CLIENT_USER_ASSOCIATED')}
                                                                                    onChange={value =>
                                                                                        handleClientUserAssociatedChange(
                                                                                            value,
                                                                                            followup_report_risk,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    value={clientUserAssociatedName}
                                                                                    options={regularClientUsers.map(e => ({
                                                                                        label: `${e.first_name} ${e.last_name}`,
                                                                                        value: e.id,
                                                                                    }))}
                                                                                    allowClear
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        {Attachment?.file_type.includes('image') ? (
                                                                            <div className={`flex w-full items-center mt-5`}>
                                                                                <div className="mr-5">{displayMessage('IMAGE')}:</div>
                                                                                <div className="flex justify-center">
                                                                                    <Tooltip title={displayMessage('CLICK_IMAGE_TO_OPEN')}>
                                                                                        <Image
                                                                                            src={api.attachments.imageDownload(
                                                                                                followup_report_risk?.attachment_id
                                                                                            )}
                                                                                            width={45}
                                                                                        />
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className={`flex w-full items-center mt-5`}>
                                                                                <div className="mr-5">{displayMessage('DOCUMENT')}:</div>
                                                                                <a
                                                                                    className="text-center"
                                                                                    key={Attachment?.id}
                                                                                    href={Attachment?.url}
                                                                                    download
                                                                                    style={{ display: 'block', marginBottom: '4px' }}
                                                                                    onClick={() => handleOnDownload(Attachment)}
                                                                                >
                                                                                    <Tooltip
                                                                                        title={Attachment?.name}
                                                                                        overlayStyle={{ maxWidth: 500 }}
                                                                                        overlayInnerStyle={{
                                                                                            backgroundColor: 'white',
                                                                                            color: 'black',
                                                                                            textAlign: 'justify',
                                                                                        }}
                                                                                    >
                                                                                        {Attachment?.name.length > max_attachment_characters
                                                                                            ? `${Attachment?.name.slice(
                                                                                                  0,
                                                                                                  max_attachment_characters
                                                                                              )}...`
                                                                                            : Attachment?.name}
                                                                                    </Tooltip>
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                        <div className={`flex w-0.5/4 items-center justify-center}`}>
                                                                            <SL_Button
                                                                                onClick={() => {
                                                                                    setIsModalConversationOpen(true)
                                                                                }}
                                                                            >
                                                                                {displayMessage('CLIENT_FOLLOWUP')}
                                                                            </SL_Button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'} w-full gap-x-5`}>
                                                                <Card
                                                                    title={displayMessage('PROBABILITY_X_GRAVITY')}
                                                                    className="w-full max-w-full bg-sl-gray-50 mt-5"
                                                                >
                                                                    <div className="flex flex-row mt-3">
                                                                        <div>{displayMessage('AFFECTED_PILLAR')}:</div>
                                                                        <div className="text-gray-400 ml-2">{e?.AffectedPillar?.title}</div>
                                                                    </div>

                                                                    <div className="flex flex-row mt-3">
                                                                        <div>{displayMessage('INSURANCE_COMPANY')}:</div>
                                                                        <div className="text-gray-400 ml-2">{e?.InsuranceCompany?.name}</div>
                                                                        <div
                                                                            style={{
                                                                                marginLeft: 10,
                                                                                width: 20,
                                                                                height: 20,
                                                                                borderRadius: 10,
                                                                                backgroundColor: e?.InsuranceCompany?.color,
                                                                            }}
                                                                        ></div>
                                                                    </div>

                                                                    <div className="flex flex-row mt-3">
                                                                        <div>{displayMessage('CONTROL_MESURE')}:</div>
                                                                        <div className="text-gray-400 ml-2">
                                                                            {e?.ControlMesure?.name} | {e?.ControlMesure?.description}
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex flex-row mt-3">
                                                                        <div>{displayMessage('SEVERITY')}:</div>
                                                                        <div className="text-gray-400 ml-2">
                                                                            {e?.Severity?.name} | {e?.Severity?.description}
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex flex-row mt-3">
                                                                        <div>{displayMessage('FREQUENCY')}:</div>
                                                                        <div className="text-gray-400 ml-2">
                                                                            {e?.Frequency?.name} | {e?.Frequency?.description}
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex flex-row mt-3">
                                                                        <div>{displayMessage('COVERAGE_SCALE')}:</div>
                                                                        <div className="text-gray-400 ml-2">
                                                                            {e?.CoverageScale?.name} | {e?.CoverageScale?.description}
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex flex-row mt-3">
                                                                        <div className="flex flex-row">
                                                                            <div>{displayMessage('PRIORITY_LEVEL')}:</div>
                                                                            <div className="text-gray-400 ml-2">
                                                                                {e?.PriorityLevel?.name} | {e?.PriorityLevel?.description}
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                marginLeft: 10,
                                                                                width: 20,
                                                                                height: 20,
                                                                                borderRadius: 10,
                                                                                backgroundColor: e?.PriorityLevel?.color,
                                                                            }}
                                                                        ></div>
                                                                    </div>

                                                                    <div className="flex flex-row mt-3 w-full">
                                                                        <div>{displayMessage('DETECTION')}:</div>
                                                                        <div className="text-gray-400 ml-2">
                                                                            {e?.Detection?.name} | {e?.Detection?.description}
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                                <div className="flex flex-col w-full gap-y-2">
                                                                    <Card
                                                                        title={displayMessage('RECOMMENDATION')}
                                                                        className="bg-sl-gray-50 mt-5 h-full"
                                                                    >
                                                                        <div className="flex flex-row p-1 mt-2">
                                                                            <div className="ml-2">{e?.Recommendation?.description}</div>
                                                                        </div>
                                                                    </Card>
                                                                    <Card
                                                                        className="w-full bg-sl-gray-50 p-0 min-h-[200px] max-h-[200px] overflow-hidden"
                                                                        title={displayMessage('NORMATIVES')}
                                                                    >
                                                                        <Tooltip
                                                                            title={renderList(
                                                                                e?.Risk?.Normatives,
                                                                                displayMessage('NO_ONE_NORMATIVE_FOR_THIS_RISK')
                                                                            )}
                                                                            placement="bottom"
                                                                            overlayStyle={{ maxWidth: 500 }}
                                                                            overlayInnerStyle={{
                                                                                backgroundColor: 'white',
                                                                                color: 'black',
                                                                                textAlign: 'justify',
                                                                            }}
                                                                        >
                                                                            {renderList(
                                                                                e?.Risk?.Normatives,
                                                                                displayMessage('NO_ONE_NORMATIVE_FOR_THIS_RISK')
                                                                            )}
                                                                        </Tooltip>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                            <div className="mt-3">
                                                                <Text className="text-primary-600 text-base" strong>
                                                                    {displayMessage('CLIENT_FOLLOWUP')}
                                                                </Text>
                                                                <SL_Grid
                                                                    sortingMode={'server'}
                                                                    components={{
                                                                        Toolbar: () => (
                                                                            <>
                                                                                <div className="flex justify-between flex-row ss:flex-row">
                                                                                    <CustomToolbar />
                                                                                    {!followUp?.end_date && (
                                                                                        <div
                                                                                            className={`flex ${
                                                                                                mobile_mode ? 'flex-col' : 'flex-row'
                                                                                            }`}
                                                                                        >
                                                                                            <SL_Button
                                                                                                icon={<PlusOutlined />}
                                                                                                className="my-1 mr-1"
                                                                                                onClick={() => setIsModalCreateMessageIsOpen(true)}
                                                                                            >
                                                                                                {displayMessage('NEW_MESSAGE')}
                                                                                            </SL_Button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        ),
                                                                    }}
                                                                    rows={followupApprovals ?? []}
                                                                    columns={[
                                                                        extraButtons,
                                                                        ...followup_approvals_grid_columns(handleOnDownload).concat(selectAttachment),
                                                                    ]}
                                                                    disableFilter={false}
                                                                    className="flex-1 min-h-[50vh] max-h-[100vh] mt-2"
                                                                    getRowClassName={params =>
                                                                        selectedRow?.id == params?.id ? 'selected-message-row' : ''
                                                                    }
                                                                />
                                                            </div>
                                                            {isModalCreateMessageIsOpen && !followUp?.end_date && (
                                                                <ModalCreateMessage
                                                                    isOpen={isModalCreateMessageIsOpen}
                                                                    onClose={() => {
                                                                        setIsModalCreateMessageIsOpen(false)
                                                                        loadFollowupApprovals()
                                                                    }}
                                                                    followupReportRisk={currentFollowupReportRisk}
                                                                />
                                                            )}
                                                            {isModalConversationOpen && (
                                                                <ModalChat
                                                                    isFollowupFinished={followUp?.end_date}
                                                                    onCloseChat={() => {
                                                                        setCollapseActiveKey(currentFollowupReportRisk?.id)
                                                                        setIsModalConversationOpen(false)
                                                                        loadFollowupApprovals()
                                                                        loadFollowUp()
                                                                        loadFollowUpReportRisks()
                                                                    }}
                                                                    followupReportRiskId={currentFollowupReportRisk?.id}
                                                                    recommendation={
                                                                        currentFollowupReportRisk?.ReportRisk?.Recommendation?.description
                                                                    }
                                                                />
                                                            )}
                                                        </Panel>
                                                    )
                                                })}
                                            </Collapse>
                                        </div>
                                        <div className="w-full flex flex-col gap-y-5 items-center justify-center mt-10">
                                            <SL_Button className={'w-[250px]'} disabled={followUp?.end_date != null} onClick={handleOnFinalization}>
                                                {displayMessage('FINALIZATE_FOLLOWUP')}
                                            </SL_Button>
                                        </div>
                                    </>
                                ),
                            },
                        ]}
                    />
                </>
            )}
        </>
    )
}
