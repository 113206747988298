import { useContext, useEffect, useState } from 'react'
import { GeneralContext } from '../../context/GeneralContext/GeneralContext'
import { Tabs } from 'antd'
import { displayMessage } from '../../helpers/messages'
import { SL_Grid } from '../../components/grid/SL_Grid'
import CustomToolbar from '../../components/grid/CustomToolbar'
import { SL_Button } from '../../components/buttons/Button'
import { useNavigate } from 'react-router-dom'
import { risks_grid_columns } from './grid_columns/risks_grid_columns'
import api from '../../services/api'
import { normatives_grid_columns } from './grid_columns/normatives_grid_columns'
import RisksFilters from './components/RisksFilters'
import NormativesFilters from './components/NormativesFilters'
import { ModalCreateRisk } from './components/ModalCreateRisk'
import { ModalCreateNormative } from './components/ModalCreateNormative'
import { ModalCreateRecomendation } from './components/ModalCreateRecommendation'
import RecommendationsFilters from './components/RecommendationsFilters'
import { recommendations_grid_columns } from './grid_columns/recommendations_grid_columns'
import { affected_pillars_grid_columns } from './grid_columns/affected_pillars_grid_columns'
import AffectedPillarsFilters from './components/AffectedPillarsFilters'
import { ModalCreateAffectedPillar } from './components/ModalCreateAffectedPillar'
import { ModalCreateStrength } from './components/ModalCreateStrength'
import StrengthFilters from './components/StrengthFilters'
import { strengths_grid_columns } from './grid_columns/strengths_grid_columns'
import { ModalCreateInsuranceCompany } from './components/ModalCreateInsuranceCompany'
import InsuranceCompanyFilters from './components/InsuranceCompanyFilters '
import { insurance_companies_grid_columns } from './grid_columns/insurance_companies_grid_columns'
import { ModalCreateActivity } from './components/ModalCreateActivity'

let activeTab = 1
const NORMATIVES_KEY = 1
const RECOMMENDATIONS_KEY = 2
const RISKS_KEY = 3
const AFFECTED_PILLARS_KEY = 4
const INSURANCIES_KEY = 5
const STRENGTHS_KEY = 6

export function Risks({}) {
    const navigate = useNavigate()
    const { mobile_mode } = useContext(GeneralContext)

    const [risks, setRisks] = useState([])
    const [normatives, setNormatives] = useState([])
    const [recommendations, setRecommendations] = useState([])
    const [affectedPillars, setAffectedPillars] = useState([])
    const [strengths, setStrengths] = useState([])
    const [insuranceCompanies, setInsuranceCompanies] = useState([])

    const [gridLoading, setGridLoading] = useState(false)

    const [rowOnFocus, setRowOnFocus] = useState(null)

    const [modalCreateRiskIsOpen, setModalCreateRiskIsOpen] = useState(false)
    const [modalCreateNormativeIsOpen, setModalCreateNormativeIsOpen] = useState(false)
    const [modalCreateActivityIsOpen, setModalCreateActivityIsOpen] = useState(false)
    const [modalCreateRecommendationIsOpen, setModalCreateRecommendationIsOpen] = useState(false)
    const [modalCreateAffectedPillarIsOpen, setModalCreateAffectedPillarIsOpen] = useState(false)
    const [modalCreateInsuranceCompanyIsOpen, setModalCreateInsuranceCompanyIsOpen] = useState(false)
    const [modalCreateStrengthIsOpen, setModalCreateStrengthIsOpen] = useState(false)

    useEffect(() => {
        handleOnTabChange(activeTab)
    }, [])

    const loadRisks = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.risks.list(params)

            setRisks(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadNormatives = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.normatives.list(params)

            setNormatives(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadRecommendations = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.recommendations.list(params)

            console.log(data)

            setRecommendations(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadAffectedPillars = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.affectedPillars.list(params)

            setAffectedPillars(data)
        } catch (error) {
        } finally {
            setGridLoading(false)
        }
    }

    const loadStrengths = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.strengths.list(params)

            setStrengths(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const loadInsurancies = async params => {
        setGridLoading(true)
        try {
            const { data } = await api.insurancies.list(params)

            setInsuranceCompanies(data)
        } catch (error) {
            console.log(error)
        } finally {
            setGridLoading(false)
        }
    }

    const onCloseNewActivityModal = () => {
        setModalCreateActivityIsOpen(false)
        loadRisks()
    }

    const tabKeysWithYourListFunction = [
        { key_code: RISKS_KEY, list_function: loadRisks },
        { key_code: NORMATIVES_KEY, list_function: loadNormatives },
        { key_code: RECOMMENDATIONS_KEY, list_function: loadRecommendations },
        { key_code: AFFECTED_PILLARS_KEY, list_function: loadAffectedPillars },
        { key_code: STRENGTHS_KEY, list_function: loadStrengths },
        { key_code: INSURANCIES_KEY, list_function: loadInsurancies },
    ]

    const handleOnTabChange = async key => {
        activeTab = key
        let listFunctionToCall = null
        tabKeysWithYourListFunction.forEach(e => {
            if (key === e.key_code) listFunctionToCall = e.list_function
            return
        })

        if (listFunctionToCall) {
            await listFunctionToCall()
        }
    }

    return (
        <>
            <Tabs
                activeKey={activeTab}
                onChange={handleOnTabChange}
                items={[
                    {
                        key: NORMATIVES_KEY,
                        label: displayMessage('NORMATIVES'),
                        children: (
                            <>
                                <div>
                                    <NormativesFilters
                                        loadNormatives={loadNormatives}
                                        setGridLoading={setGridLoading}
                                        setNormatives={setNormatives}
                                    />

                                    {modalCreateNormativeIsOpen && (
                                        <ModalCreateNormative
                                            isOpen={modalCreateNormativeIsOpen}
                                            onClose={setModalCreateNormativeIsOpen}
                                            reloadNormatives={loadNormatives}
                                        />
                                    )}

                                    

                                    <SL_Grid
                                        rows={normatives ?? []}
                                        sortingMode={'server'}
                                        components={{
                                            Toolbar: () => (
                                                <>
                                                    <div className="flex justify-between flex-row ss:flex-row">
                                                        <CustomToolbar />
                                                        <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                            <SL_Button
                                                                className="my-1 mr-1"
                                                                disabled={!rowOnFocus}
                                                                onClick={() => navigate(`/risks/normative/${rowOnFocus.id}`)}
                                                            >
                                                                {displayMessage('NORMATIVE_DETAILS')}
                                                            </SL_Button>

                                                            <SL_Button className="my-1 mr-1" onClick={() => setModalCreateNormativeIsOpen(true)}>
                                                                {displayMessage('CREATE_NORMATIVE')}
                                                            </SL_Button>
                                                            
                                                        </div>
                                                    </div>
                                                </>
                                            ),
                                        }}
                                        disableFilter={false}
                                        onRowClick={params => {
                                            setRowOnFocus(params.row)
                                        }}
                                        columns={normatives_grid_columns}
                                        className="flex-1 min-h-[61vh] max-h-[100vh]"
                                        loading={gridLoading}
                                    />
                                </div>
                            </>
                        ),
                    },
                    {
                        key: RECOMMENDATIONS_KEY,
                        label: displayMessage('RECOMMENDATIONS'),
                        children: (
                            <>
                                <div>
                                    {modalCreateRecommendationIsOpen && (
                                        <ModalCreateRecomendation
                                            isOpen={modalCreateRecommendationIsOpen}
                                            onClose={setModalCreateRecommendationIsOpen}
                                            reloadRecommendations={loadRecommendations}
                                        />
                                    )}
                                    <RecommendationsFilters
                                        loadRecommendations={loadRecommendations}
                                        setGridLoading={setGridLoading}
                                        setRecommendations={setRecommendations}
                                    />
                                    <SL_Grid
                                        rows={recommendations ?? []}
                                        sortingMode={'server'}
                                        components={{
                                            Toolbar: () => (
                                                <>
                                                    <div className="flex justify-between flex-col ss:flex-row">
                                                        <CustomToolbar />
                                                        <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                            <SL_Button
                                                                className="my-1 mr-1"
                                                                disabled={!rowOnFocus}
                                                                onClick={() => navigate(`/risks/recommendation/${rowOnFocus.id}`)}
                                                            >
                                                                {displayMessage('RECOMMENDATION_DETAILS')}
                                                            </SL_Button>
                                                            <SL_Button className="my-1 mr-1" onClick={() => setModalCreateRecommendationIsOpen(true)}>
                                                                {displayMessage('CREATE_RECOMMENDATION')}
                                                            </SL_Button>
                                                        </div>
                                                    </div>
                                                </>
                                            ),
                                        }}
                                        disableFilter={false}
                                        onRowClick={params => {
                                            setRowOnFocus(params.row)
                                        }}
                                        columns={recommendations_grid_columns}
                                        className="flex-1 min-h-[61vh] max-h-[100vh]"
                                        loading={gridLoading}
                                    />
                                </div>
                            </>
                        ),
                    },
                    {
                        key: RISKS_KEY,
                        label: displayMessage('RISKS'),
                        children: (
                            <>
                                <div>
                                    <RisksFilters loadRisks={loadRisks} setGridLoading={setGridLoading} setRisks={setRisks} />

                                    {modalCreateRiskIsOpen && (
                                        <ModalCreateRisk isOpen={modalCreateRiskIsOpen} onClose={setModalCreateRiskIsOpen} reloadRisks={loadRisks} />
                                    )}

                                    {modalCreateActivityIsOpen && (
                                        <ModalCreateActivity
                                            isOpen={modalCreateActivityIsOpen}
                                            onClose={setModalCreateActivityIsOpen}
                                            reloadActivities={loadRisks}
                                        />
                                    )}

                                    <SL_Grid
                                        rows={risks ?? []}
                                        components={{
                                            Toolbar: () => (
                                                <>
                                                    <div className="flex justify-between flex-row ss:flex-row">
                                                        <CustomToolbar />
                                                        <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                            <SL_Button
                                                                className="my-1 mr-1"
                                                                disabled={!rowOnFocus}
                                                                onClick={() => navigate(`/risks/risk/${rowOnFocus.id}`)}
                                                            >
                                                                {displayMessage('RISK_DETAILS')}
                                                            </SL_Button>

                                                            <SL_Button className="my-1 mr-1" onClick={() => setModalCreateRiskIsOpen(true)}>
                                                                {displayMessage('CREATE_RISK')}
                                                            </SL_Button>
                                                            <SL_Button className="my-1 mr-1" onClick={() => setModalCreateActivityIsOpen(true)}>
                                                                {displayMessage('CREATE_ACTIVITY')}
                                                            </SL_Button>
                                                        </div>
                                                    </div>
                                                </>
                                            ),
                                        }}
                                        disableFilter={false}
                                        onRowClick={params => {
                                            setRowOnFocus(params.row)
                                        }}
                                        columns={risks_grid_columns}
                                        className="flex-1 min-h-[61vh] max-h-[100vh]"
                                        loading={gridLoading}
                                    />
                                </div>
                            </>
                        ),
                    },
                    {
                        key: AFFECTED_PILLARS_KEY,
                        label: displayMessage('AFFECTED_PILLARS'),
                        children: (
                            <div>
                                {modalCreateAffectedPillarIsOpen && (
                                    <ModalCreateAffectedPillar
                                        isOpen={modalCreateAffectedPillarIsOpen}
                                        onClose={setModalCreateAffectedPillarIsOpen}
                                        reloadAffectedPillars={loadAffectedPillars}
                                    />
                                )}
                                <AffectedPillarsFilters
                                    loadAffectedPillars={loadAffectedPillars}
                                    setGridLoading={setGridLoading}
                                    setAffectedPillars={setAffectedPillars}
                                />
                                <SL_Grid
                                    rows={affectedPillars ?? []}
                                    sortingMode={'server'}
                                    components={{
                                        Toolbar: () => (
                                            <>
                                                <div className="flex justify-between flex-col ss:flex-row">
                                                    <CustomToolbar />
                                                    <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                        <SL_Button
                                                            className="my-1 mr-1"
                                                            disabled={!rowOnFocus}
                                                            onClick={() => navigate(`/risks/affected-pillar/${rowOnFocus.id}`)}
                                                        >
                                                            {displayMessage('AFFECTED_PILLAR_DETAILS')}
                                                        </SL_Button>
                                                        <SL_Button className="my-1 mr-1" onClick={() => setModalCreateAffectedPillarIsOpen(true)}>
                                                            {displayMessage('CREATE_AFFECTED_PILLAR')}
                                                        </SL_Button>
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                    }}
                                    disableFilter={false}
                                    onRowClick={params => {
                                        setRowOnFocus(params.row)
                                    }}
                                    columns={affected_pillars_grid_columns}
                                    className="flex-1 min-h-[61vh] max-h-[100vh]"
                                    loading={gridLoading}
                                />
                            </div>
                        ),
                    },
                    {
                        key: INSURANCIES_KEY,
                        label: displayMessage('INSURANCE_COMPANIES'),
                        children: (
                            <div>
                                {modalCreateInsuranceCompanyIsOpen && (
                                    <ModalCreateInsuranceCompany
                                        isOpen={modalCreateInsuranceCompanyIsOpen}
                                        onClose={setModalCreateInsuranceCompanyIsOpen}
                                        reloadInsurancies={loadInsurancies}
                                    />
                                )}
                                <InsuranceCompanyFilters
                                    loadInsurancies={loadInsurancies}
                                    setGridLoading={setGridLoading}
                                    setInsurancies={setInsuranceCompanies}
                                />
                                <SL_Grid
                                    rows={insuranceCompanies ?? []}
                                    sortingMode={'server'}
                                    components={{
                                        Toolbar: () => (
                                            <>
                                                <div className="flex justify-between flex-col ss:flex-row">
                                                    <CustomToolbar />
                                                    <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                        <SL_Button
                                                            className="my-1 mr-1"
                                                            disabled={!rowOnFocus}
                                                            onClick={() => navigate(`/risks/insurance/${rowOnFocus.id}`)}
                                                        >
                                                            {displayMessage('INSURANCE_COMPANY_DETAILS')}
                                                        </SL_Button>
                                                        <SL_Button className="my-1 mr-1" onClick={() => setModalCreateInsuranceCompanyIsOpen(true)}>
                                                            {displayMessage('CREATE_INSURANCE_COMPANY')}
                                                        </SL_Button>
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                    }}
                                    disableFilter={false}
                                    onRowClick={params => {
                                        setRowOnFocus(params.row)
                                    }}
                                    columns={insurance_companies_grid_columns}
                                    className="flex-1 min-h-[61vh] max-h-[100vh]"
                                    loading={gridLoading}
                                />
                            </div>
                        ),
                    },
                    {
                        key: STRENGTHS_KEY,
                        label: displayMessage('STRENGHTS'),
                        children: (
                            <div>
                                <StrengthFilters loadStrengths={loadStrengths} setGridLoading={setGridLoading} setStrengths={setStrengths} />

                                {modalCreateStrengthIsOpen && (
                                    <ModalCreateStrength
                                        isOpen={modalCreateStrengthIsOpen}
                                        onClose={setModalCreateStrengthIsOpen}
                                        reloadStrengths={loadStrengths}
                                    />
                                )}

                                <SL_Grid
                                    rows={strengths ?? []}
                                    sortingMode={'server'}
                                    components={{
                                        Toolbar: () => (
                                            <>
                                                <div className="flex justify-between flex-col ss:flex-row">
                                                    <CustomToolbar />
                                                    <div className={`flex ${mobile_mode ? 'flex-col' : 'flex-row'}`}>
                                                        <SL_Button
                                                            className="my-1 mr-1"
                                                            disabled={!rowOnFocus}
                                                            onClick={() => navigate(`/risks/strength/${rowOnFocus.id}`)}
                                                        >
                                                            {displayMessage('STRENGTH_DETAILS')}
                                                        </SL_Button>
                                                        <SL_Button className="my-1 mr-1" onClick={() => setModalCreateStrengthIsOpen(true)}>
                                                            {displayMessage('CREATE_STRENGTH')}
                                                        </SL_Button>
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                    }}
                                    disableFilter={false}
                                    onRowClick={params => {
                                        setRowOnFocus(params.row)
                                    }}
                                    columns={strengths_grid_columns}
                                    className="flex-1 min-h-[61vh] max-h-[100vh]"
                                    loading={gridLoading}
                                />
                            </div>
                        ),
                    },
                ]}
            />
        </>
    )
}
